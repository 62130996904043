//
// Nav
//


// Nav wrapper (container)

// Nav wrapper
.nav-wrapper {
    padding: 1rem 0;
    @include border-top-radius($card-border-radius);

    + .card {
        @include border-top-radius(0);
        @include border-bottom-radius($card-border-radius);
    }
}


// Nav links

.nav-link {
    // color: $nav-link-color;

    &:hover {
        color: var(--primary-color);
    }

    i.ni {
        position: relative;
        top: 2px;
    }
}


.nav-link.disabled {
  background-color: $custom-control-indicator-disabled-bg !important;
  color: $custom-control-label-disabled-color !important;
}
