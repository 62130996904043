@import 'ngx-toastr/toastr';
/* dataTable Sorting*/
th.sortable,
th[sortable] {
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: underline;
  font-weight: bolder;
}

th.sortable:before,
th[sortable]:before {
  display: block;
  font-family: "Font Awesome 5 Free";
  content: "\f0dc";
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th.sortable.asc:before,
th[sortable].asc:before {
  display: block;
  font-family: "Font Awesome 5 Free";
  content: "\f0d7";
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th.sortable.desc:before,
th[sortable].desc:before {
  display: block;
  font-family: "Font Awesome 5 Free";
  content: "\f0d8";
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

td{
  vertical-align: middle !important;
}

/* end - dataTable Sorting*/

ngb-typeahead-window.dropdown-menu {
  max-height: 200px !important;
  overflow-y: auto;
  width: auto !important;
}

.form-control {
  color: #2b2b2b !important;
}

/*invoice-import*/
.btn.btn-link {
  width: 100% !important;
  height: 100% !important;
  text-align: left !important;
  border-radius: 0px;
}

.btn.btn-link.collapsed {
  background-color: transparent !important;
  width: 100% !important;
  height: 100% !important;
  text-align: left !important;
}
.table-import {
  tbody:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  tr:nth-of-type(even) {
    background-color: transparent;
  }

  tr:nth-of-type(even) {
    background-color: white;
  }
}
.table-document {
  tbody:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  tr:nth-of-type(even) {
    background-color: transparent;
  }

  tr:nth-of-type(even) {
    background-color: white;
  }
}

.modalInvoice {
  &.out .modal-dialog.modal-xl {
    animation: ease-in-out 0.5s sidebar-out;
    max-width: calc(100vw - 300px);
    margin-right: 10px;
    margin-top: 0px !important;
  }

  &.in .modal-dialog.modal-xl {
    animation: ease-in-out 0.5s sidebar-in;
    max-width: calc(100vw - 20px);
    margin-right: 10px;
  }

  .modal-dialog.modal-lg {
    margin-right: 200px;
    max-width: calc(100vw - 500px);
    max-height: calc(100vh - 130px);
  }

  .modal-body {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
  }

  transition: all 0.5s ease-in-out;
  max-height: calc(100vh - 70px);
  margin-top: 65px;
}

@keyframes sidebar-in {
  from {
    max-width: calc(100vw - 270px);
  }

  to {
    max-width: calc(100vw - 20px);
  }
}

@keyframes sidebar-out {
  from {
    max-width: calc(100vw - 20px);
  }

  to {
    max-width: calc(100vw - 270px);
  }
}

//sidebar
.navbar-vertical {
  top: 0;
}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-light-blue::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-light-blue::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar-light-blue::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--secondary-color);
}

.scrollbar-light-blue {
  scrollbar-color: var(--secondary-color);
}

.waitcursor {
  cursor: wait !important;
}

.dropdown-btn {
  border: none !important;
  margin-top: -5px !important;
}

.dropdown-multiselect__caret {
  margin-top: -5px !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color:  var(--primary-color) !important;
  background-color:  transparent !important;
  border-color:  var(--primary-color);
}

// button:not(:disabled):not(.disabled):active,
// button:not(:disabled):not(.disabled).active,
// .show > button {
//   color:  var(--primary-color) !important;
//   background-color:  transparent !important;
//   border-color:  var(--primary-color);
// }

// a:not(:disabled):not(.disabled):active,
// a:not(:disabled):not(.disabled).active,
// .show > a {
//   color:  var(--primary-color) !important;
//   background-color:  transparent !important;
//   border-color:  var(--primary-color);
// }

// a:hover{
//   color:  var(--secondary-color) !important;
//   background-color:  var(--primary-color) !important;
//   border-color:  var(--primary-color);
// }

input:not(:disabled):not(.disabled):active,
input:not(:disabled):not(.disabled).active,
.show > input {
  color:  var(--primary-color) !important;
  background-color:  transparent !important;
  border-color:  var(--primary-color);
}

span:not(:disabled):not(.disabled):active,
span:not(:disabled):not(.disabled).active,
.show > span {
  color:  var(--secondary-color) !important;
  background-color:  transparent !important;
  border-color:  var(--primary-color);
}

table thead {
  text-align: center !important;
}

table tr {
  text-align: center !important;
}
