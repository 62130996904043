.main-content {
  position: relative;
  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: $navbarcolor;
    border-bottom: 0.1px solid $gray-300;
  }
  .nav-header {
    background-color: var(--primary-color);
    padding: 7px 0;
  }
  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }

  .main-panel {
    // padding-top: 59px !important;
    // padding-left: 10px !important;
    min-height: calc(100vh - 40px);
  }
}

.main-content-customer {
  position: relative;
  background-color: #f0f2f5;
  // Navbar
  .navbar-top {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: $white;
    border-bottom: 0.1px solid $gray-300;
  }
  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }

  .header {
    background-color: $white;
    margin-top: 68px !important;
  }

  .main-panel {
    margin-top: 52px !important;
    // padding-left: 175px !important;
    min-height: calc(100vh - 140px);
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}
