@media screen and (min-width: 768px) {
  // html:not(.auth-layout) .main-content {
      // margin-left: $navbar-vertical-width;
  // }
}

.fixed-right{
  .navbar-vertical.navbar-expand-md{
    right: 0;
    border-width: 0 0 0 1px;
  }
   .main-content {
    margin-right: $navbar-vertical-width;
    margin-left: 0;
  }
}
