//
// Card
//

.card{
    border-radius: 15px;
}

.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}
