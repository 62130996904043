//
// Custom checkbox
//

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-checkbox {
    .custom-control-input ~ .custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}
