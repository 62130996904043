.custom-text-white {
  color: #fff !important;
}

.custom-text-primary {
  color: var(--primary-color);
}

.btn-primary.btn-custom-primary {
  &:hover {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
  }
}

.btn-outline-dark.btn-custom-secondary {
  border-color: var(--primary-color);
  color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.body-container {
  max-width: 1380px;
  margin: 0 auto;
}

.main-wrapper{
  margin-top: 58px;
}